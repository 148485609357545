@use "colors";
@use "typography";
@import "custom";

.location-card-container {
  @include typography.body-small;

  color: colors.$off-black;
  cursor: auto;

  a {
    outline: none;

    .title {
      span {
        border: 1px solid transparent;
        inset-inline-start: -6px;
        padding: 3px 6px;
        position: relative;
      }
    }

    &:focus {
      .title {
        span {
          border: 1px solid colors.$black;
        }
      }
    }
  }

  .image-wrap {
    overflow: hidden;

    a {
      outline: none;
    }

    img {
      block-size: 237px;
      inline-size: 100%;
      transition: transform 300ms ease-in-out;
    }
  }

  .title {
    @include typography.subhead-regular;

    color: colors.$black;
    margin-block: 28px 15px;
  }

  .featured-amenities-container {
    margin-block-start: 15px;

    .amenities-title {
      margin-block-end: 5px;
    }

    ul {
      padding-inline-start: 15px;
    }
  }
}

.location-card-container:hover {
  img {
    transform: scale(1.025);
  }
}

@include media-breakpoint-down(md) {
  .location-card-container {
    border-block-start: solid colors.$light-gray 1px;
    justify-content: space-between;
    padding-block: 32px;

    .title {
      margin-block-start: 0;
    }
  }

  .responsove-thumbnail {
    block-size: 133px;
    inline-size: 199px;
  }
}

@include media-breakpoint-down(sm) {
  .location-card-container {
    .responsove-thumbnail {
      block-size: 99px;
      inline-size: 138px;
      margin-inline-start: 15px;
    }
  }
}
